import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import PageStartBanner from "../components/PageStartBanner"
import TwoColumnProduct from "../components/TwoColumnProduct"
const CabinPage = ({
  pageContext,
  data: {
    datoCmsCabinPage: { title, seo, description, image, cabinCategory, head },
    miscString,
  },
}) => {
  return (
    <Layout
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.image?.seoImageUrl}
    >
      <PageStartBanner title={head[0].title} tag={head[0].tag} />
      {cabinCategory.map((cabinCat, index) => (
        <TwoColumnProduct
          title={cabinCat.title}
          description={cabinCat.description}
          linkUrl={cabinCat.link.slug}
          heroimage={cabinCat.image?.gatsbyImageData}
          reverse={index % 2 ? true : false}
          cta={miscString.nodes[0].learnMore}
          isDark={index % 2 ? true : false}
        />
      ))}
    </Layout>
  )
}

export default CabinPage

export const query = graphql`
  query CabinPageQuery($locale: String!) {
    datoCmsCabinPage(locale: { eq: $locale }) {
      locale
      id: originalId
      title
      description
      image {
        gatsbyImageData(height: 350)
      }
      head {
        tag
        title
      }
      cabinCategory {
        id: originalId
        title
        description
        image {
          gatsbyImageData(height: 300)
        }
        link {
          title
          slug
        }
      }
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }
    miscString: allDatoCmsMiscString(filter: { locale: { eq: $locale } }) {
      nodes {
        getInquiry
        learnMore
      }
    }
  }
`
